.table-data-visualization-container {
  margin-top: 20px;
}

.table-data-visualization-container.table-overflow {
  font-size: small;
}

.table {
  border-collapse: separate;
  border-spacing: 0px;
}

.table > thead > tr > th.table-stratification-title {
  border-top: 2px solid #00695c;
  border-right: 2px solid #00695c;
  border-left: 1px solid #00695c;
  border-bottom: none;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  color: #00695c;
  font-weight: bolder;
  box-shadow: -1px 0px 0px 0px #00695c;
}

th.table-location-year,
th.table-td,
.table td {
  border: 1px solid #606060 !important;
  background-color: #fff;
}

th.table-location-year {
  text-align: left;
}

th.table-location-year,
.table tbody tr.table-primary {
  background-color: #ceece7;
}

.table tbody tr.table-primary td {
  background-color: #ceece7;
  color: #00695c;
  font-weight: bold;
}

th.table-td {
  font-weight: normal;
}

.table tbody tr.main-table-value th.table-td,
.table tbody tr.main-table-value td {
  font-weight: bold;
}

.font-primary {
  color: #00695c;
}

@media (max-width: 767px) {
  .table-data-visualization-container {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
    padding: 0;
    font-size: small;
    overflow-x: scroll;
  }

  .table-data-visualization-container.table-overflow {
    font-size: small;
    height: fit-content;
    max-height: 500px;
    overflow-x: scroll;
  }
}

@media print {
  .table-data-visualization-container {
    overflow-x: visible;
    max-height: none !important;
    height: auto !important;
    print-color-adjust: exact !important;
  }

  th.table-stratification-title {
    border-top: 2px solid #00695c !important;
    border-right: 2px solid #00695c !important;
    border-left: 2px solid #00695c !important;
    border-bottom: none;
    color: #00695c;
    font-weight: bolder;
    background-color: inherit !important;
    print-color-adjust: exact !important;
  }

  /* tr.table-primary th.table-location-year  */
  tbody:first-child {
    border-top: 2px solid #000 !important;
    color: #00695c !important;
    font-weight: bolder;
    background-color: inherit !important;
    print-color-adjust: exact !important;
  }

  .table-primary th,
  .table-primary td {
    background-color: inherit !important;
    print-color-adjust: exact !important;
  }
}
