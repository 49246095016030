.tick text {
  /* font-size: 17px; */
}

.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.yAxisLabel {
  transform: translateX(-47px) translateY(160px) rotate(270deg);
  text-anchor: middle;
  font-size: large;
}

#stateSeparators path {
  stroke: none;
}
#stateSeparators .tick {
  color: #000;
}

.tick {
  max-width: 25px !important;
  width: 20px !important;
}

.ToolTipContainer {
  text-align: left;
  position: absolute;
  margin: 20px;
  border: 1px solid lightgrey;
  padding: 15px;
  background-color: white;
  line-height: 25px;
  z-index: 9999;
  max-width: 265px;
  overflow: visible;
}

.ToolTipContainer .toolTipLocationOrYear,
.ToolTipContainer .toolTipDataValue,
.ToolTipContainer .toolTipViewBy {
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.3rem;
  font-size: 1rem;
}

.ToolTipContainer .toolTipCI {
  font-weight: normal;
  margin-bottom: 0;
  line-height: 1.3rem;
  font-size: 1rem;
}

.ToolTipContainer .ToolTipLocation {
  font-weight: bold;
}

.ToolTipContainer .ToolTipLocation .allYearsToolTip {
  line-height: 0.6;
  font-size: 18px;
}

.ToolTipContainer .ToolTipLocation .noTextTransformation {
  text-transform: none;
}

.ToolTipContainer .close-button {
  margin-top: -10px;
  font-weight: bold;
}

title {
  display: block !important;
}

.horizontalGraphLegendContainer {
  position: relative;
}

.horizontalGraphLegend {
  position: absolute;
  right: 0;
  min-height: 150px;
  padding: 20px;
  border: 1px solid #000;
  font-size: small;
  text-align: left;
}

.horizontalLegendItems {
  font-size: small;
  padding-bottom: 10px;
}

.numberSectionTitles {
  text-align: left;
  margin-top: 40px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Open Sans Medium", apple-system, blinkmacsystemfont, "Segoe UI",
    "Helvetica Neue", arial, sans-serif !important;
}

.dataTypeTitle {
  margin-top: 30px;
}

.legendItem {
  margin-right: 15px;
}

@media (max-width: 767px) {
  .horizontal-chart {
    height: 815px;
    width: 100%;
  }

  .horizontal-chart svg {
    height: 985px;
    transform: translate(0, 0);
  }

  .horizontal-chart-content {
    scale: 0.85;
    transform: translate(145px, 30px);
  }

  .horizontal-chart-content .USValueLine {
    transform: translate(0, -28px);
  }

  .horizontal-chart svg .tick text {
    font-size: 22px !important;
  }

  .dataTypeTitle {
    margin-left: 20px;
  }

  .horizontalGraphLegend {
    max-width: 100% !important;
    position: static;
    margin-left: 20px;
  }

  .horizontalGraphViewAll {
    width: 100%;
    height: 100%;
  }

  .horizontalGraphViewAll svg {
    width: 86%;
    transform: translate(0, 0);
  }

  .horizontalGraphViewAll svg .tick text {
    font-size: 17px;
  }

  .horizontal-viewall-content {
    transform: translate(54px, 10px);
  }

  .vertical-chart {
    height: 190px;
  }

  .vertical-chart svg {
    scale: 0.5;
    transform: translate(-300px, -200px);
  }

  .vertical-chart svg .tick text {
    font-size: 18px;
  }

  .vertical-chart.vertical-chart-overflow {
    font-size: small;
    height: fit-content;
    overflow-x: scroll;
    max-height: 510px;
    margin-bottom: -40px;
  }

  .vertical-chart.vertical-chart-overflow svg {
    scale: 1;

    transform: translate(5px, 0px);
  }

  .vertical-chart.vertical-chart-overflow svg .tick text {
    font-size: 12px;
  }

  .trendline-chart {
    height: 190px;
  }

  .trendline-chart svg {
    scale: 0.5;
    transform: translate(-300px, -200px);
  }

  .trendline-chart svg .tick text {
    font-size: 16px;
  }

  .data-visualization-container {
    display: flex;
    flex-wrap: wrap;
  }

  .dataTypeTitle {
    width: 100%;
  }

  .horizontalGraphLegendContainer {
    padding-top: 30px;
  }
}

@media print {
  .yAxisLabel {
    transform: translateX(-47px) translateY(160px) rotate(270deg);
    font-size: medium;
  }

  .vertical-chart,
  .trendline-chart {
    height: 665px;
  }

  .vertical-chart svg,
  .trendline-chart svg {
    scale: 1.4;
    transform: translate(120px, 50px);
  }
  .vertical-chart.vertical-chart-overflow {
    overflow: visible !important;
    height: 665px;
    margin-bottom: 50px;
    font-size: 5px;
  }
  .vertical-chart.vertical-chart-overflow svg {
    scale: 1.4;
    transform: translate(120px, 50px);
  }

  .col-print-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .horizontalGraphViewAll {
    max-height: 100%;
    max-width: 90%;
  }

  .horizontalGraphViewAll .tick text {
    font-size: 125%;
  }

  .print-horizontal-legend {
    width: 100% !important;
  }

  .horizontalGraphLegendContainer {
    position: static;
  }

  .horizontalGraphLegend {
    position: static;
    width: 100%;
    min-height: 160px;
    padding: 20px;
    border: 1px solid #000;
    font-size: 20px !important;
    text-align: left;
  }

  .horizontalLegendItems {
    font-size: 125%;
    padding-bottom: 10px;
  }

  .trendline-legendItem {
    margin-left: 55px !important;
  }
  .trendline-legendItem svg {
    scale: 1.75;
    margin-right: 15px;
  }

  /* .data-source-label {
    transform: translate(0px 150px);
  } */
}
