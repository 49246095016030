.LegendContainer {
    text-align: left;
    width: 240px;
    position: absolute;
    border: 1.5px solid lightgrey;
    background-color: white;
    top: 115px;
    right: -10px;
    font-size: medium;
}


.LegendItemHeader {
    font-weight: bold;
}


.dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.LegendItem {
    text-align: left;
    margin-left: 15px;
}



.StratificationContainer {
    text-align: left;
    width: 240px;
    position: absolute;
    border: 1px solid lightgrey;
    background-color: white;
    top: 0px;
    right: -10px;
    font-size: medium;
}

.MapZoomContainer {
    text-align: left;
    position: absolute;
    border: 1px solid lightgrey;
    background-color: white;
    top: -50px;
    left: -50px;
    font-size: large;
}

.MapNoDataContainer {
    text-align: center;
    width: 250px;
    border: 1px solid lightgrey;
    background-color: white;
    font-size: large;
}


.zoom-button {
    width: 35px;
    height: 35px;
}

.ToolTipContainer {
    text-align: left;
    position: absolute;
    border: 1px solid lightgrey;
    background-color: white;
    top: 0px;
    left: 1000px;
    font-size: small;
    min-width: 300px;
}

    .ToolTipContainer .ToolTipLocation {
        font-weight: bold;
    }

    .ToolTipContainer .ToolTipHeader {
        background-color: grey;
    }

        .ToolTipContainer .ToolTipHeader .btn.btn-link {
            color: white;
            font-weight:900;
            font-size:20px;
        }
.header-text{
    color:white;
    font-size:18px;    
}

.states .hovered, .states :hover {
    opacity: .8 !important;
}


@media (max-width:767px) {
    .map-mobile-scale{
        scale: .5;
        top: 0;
        right:-60px;
}
    .MapZoomContainer{
        top: 75px;
        left: -30px;
}

.ToolTipContainer{
    left: 30px !important;
    border: 2px solid #000;
}

.map-toolTipContainer .ToolTipContainer{
    top: 10px !important;
    left: 30px !important;
    border: 2px solid #000;
}
}

@media print{
    .dot {
        print-color-adjust: exact !important;
          
    }
}
