.comparison-report {
}
.comparison-report-container {
}
.category-item-wrapper {
  border-top: 1px solid #00695c;
  background-color: #deeee9;
}

.comparison-report-header-container {
  background-color: rgb(245, 245, 245);
  border: 1px solid lightgrey;
}

.comparison-report-header-container2 {
  background-color: white;
}
.category-toggle {
  cursor: pointer !important;
  border-left: 1px solid #00695c;
  border-right: 1px solid #00695c;
  border-bottom: 1px solid #00695c;
}
.category-toggle a {
  text-decoration: none;
  display: block;
  margin-top: 7px;
  margin-bottom: 7px;
  font-weight: bold;
}

.comparison-indicator-table {
  background-color: white;
  width: 100%;
  border-left: 1px solid #00695c;
  border-right: 1px solid #00695c;
}

.header-row {
  background-color: #deeee9;
  border-bottom: 1px solid #00695c;
}

.header-question {
  border-right: 1px solid #00695c;
  padding: 5px;
  font-weight: bold;
  padding-left: 15px;
  width: 25%;
  font-size: 16px;
}

.header-data-value-type {
  border-right: 1px solid #00695c;
  padding: 5px;
  font-weight: bold;
  text-align: center;
  width: 25%;
  font-size: 16px;
}

th.header-data-value {
  border-right: 1px solid #00695c;
  padding: 5px;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}

th.header-data-value:last-child {
  border-right: none;
}

tr.indicator-row {
  border-bottom: 1px solid #00695c;
}

.indicator-row td {
  padding: 15px;
}

tr.indicator-row:nth-child(even) {
  background-color: rgb(245, 245, 245) !important;
}

.indicator-question {
  border-right: 1px solid #00695c;
  font-weight: 100;
  font-size: 16px;
}

.indicator-data-value-type {
  border-right: 1px solid #00695c;
  font-weight: 100;
  text-align: center;
  font-size: 16px;
}

td.indicator-data-value {
  border-right: 1px solid #00695c;
  font-weight: 100;
  text-align: center;
  font-size: 16px;
}

td.indicator-data-value:last-child {
  border-right: none;
}

.text-high-light {
  background-color: yellow;
}

.no-indicators {
  text-align: center;
}

.mark,
mark {
  padding: 0;
  background-color: yellow;
}

.modal-backdrop-orange {
  pointer-events: none;
}

.hide {
  display: none;
}

.show {
  display: block;
}
