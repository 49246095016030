.removeLeftBorderRadius{
    border-top-left-radius:0px;
    border-bottom-left-radius:0px;
}

.removeRightBorderRadius{
    border-top-right-radius:0px;
    border-bottom-right-radius:0px;
}

.dataVisualizationSelected {
    background-color: rgba(216, 216, 216, 1) !important;
}

.dataVisualizationButtonDefault {
    border: .5px solid #bdbdbd !important;
    color: #00695c !important;
    font-size: 14px !important;
    width: 100px !important;
}