* {
  font-family: "Open Sans";
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.topFilterButtons {
  padding-top: 10px;
}

.indicatorDefinition {
  position: relative;
  width: fit-content;
  height: 0px;
}

.indicatorDefinition button {
  position: absolute;
  left: 50px;
  padding: 0;
  margin: 0;
  text-decoration: underline;
  font-size: medium;
}

.btn.btn-primary[is-selected="false"] {
  color: #000000;
  background-color: #f8f8f8;
}

.btn.btn-primary[is-selected="false"]:hover,
.btn.btn-primary[is-selected="false"]:visited,
.btn.btn-primary[is-selected="false"]:active,
.btn.btn-primary[is-selected="false"]:focus {
  color: #000;
  background-color: #efefef;
  text-decoration: none;
  box-shadow: none;
}

.section-card.btn.btn-primary[is-disabled="true"] {
  color: #000000 !important;
  background-color: lightgrey;
}

.section-card.btn.btn-primary:disabled:hover {
  background-color: #00695c;
}

.hidden {
  display: none !important;
}

.error-message {
  color: #cc0000;
  font-size: 1rem;
}

.sideFilter .h5 {
  font-weight: bold;
}

.sideFilter label {
  width: 100%;
}

.sideFilter-container {
  border-right: 1px solid #e0e0e0;
}

.sideFilter-border {
  border-top: 2px solid #e0e0e0;
  height: 0px;
  padding: 0;
  margin: 15px 0px 15px 0px;
  width: 35%;
}

.data-visualization-container {
  overflow: visible;
}

.viewByTitleContainer {
  text-transform: lowercase;
}
.viewByTitle {
  text-transform: capitalize;
}

a.link-style:not([href]) {
  color: #075290;
  text-decoration: underline;
}

a.link-style:not([href]):hover {
  cursor: pointer;
}

.DataVizHeaderContainer {
}

.DataVizHeaderContainer h4 {
  font-weight: 600;
  font-size: 20px;
}

.DataVizHeaderContainer h5 {
  font-size: 17px;
}

.stratification-container {
  margin-top: -5px;
  margin-bottom: -5px;
}

.stratification-label {
  margin-top: 5px;
  margin-right: 0px;
  padding-right: 0px;
}

.stratification-dropdown {
  margin-top: -8px;
  padding-left: 0px;
  margin-left: 0px;
  width: fit-content;
}

.stratification-dropdown .form-group select {
  display: inline-block;
  width: fit-content;
  margin-left: 10px;
}

.topFilterSelector .form-group select {
  margin-top: 10px;
  width: 330px;
}

.black-text-nav-links button {
  color: black !important;
}

.nav-link {
  font-weight: 100 !important;
  font-size: medium;
}

.navbar button {
  width: 100%;
  text-align: center;
}

.navbar ol li,
.navbar ul li {
  min-width: 100px;
  text-align: right !important;
}

.navbar {
  padding: 0px 0px 0px 0px !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.nav-dropdown {
  background: #fff;
  z-index: 999999;
  top: 45px;
  left: -4px;
  border: 2px solid #4ebaaa;
  width: fit-content;
}

.navbar .nav-dropdown button {
  text-align: left !important;
  background: none;
  border: none;
  width: 120%;
}

.nav-item {
  padding: 0px 0px 0px 0px !important;
  margin: 0px 0px 0px 0px !important;
  align-items: start;
}

.nav-item button {
  border-radius: 0;
  transition: none;
}

.nav-item:hover {
  background-color: rgb(0, 105, 92) !important;
  color: white !important;
}

.nav-item button:hover {
  background-color: rgb(0, 105, 92) !important;
  color: white !important;
}

.nav-item[is-selected="true"],
.nav-item[is-selected="true"] button {
  background-color: rgb(0, 105, 92) !important;
  color: white !important;
}

.dropdown-toggle-link.btn.btn-link.nav-link {
  color: #000;
}

.buttonMinWidth {
  min-width: 150px;
}

.back-to-top {
  position: fixed;
  bottom: 10vh !important;
  right: 0 !important;
  width: 50px !important;
  border: 3px solid #fff !important;
  border-right: none !important;
  border-radius: 30px 0 0 30px !important;
}

.back-to-top {
  z-index: 999;
}

.back-to-top span {
  font-size: 25px;
}

.back-to-top-arrow {
  font-weight: bold;
}

.map-icon-container .col {
  line-height: 25px;
}
.map-icon-green {
  color: #00695c;
  font-size: 37px;
  margin-left: -5px;
}
.map-icon-text {
  text-align: left;
  font-size: 13px;
}

.no-data {
  border: 1px solid grey;
}

.foot-notes {
  font-size: 13px;
}

@media (max-width: 767px) {
  .form-group select {
    font-size: 13px;
    overflow-x: hidden;
  }

  .topFilterSelector .form-group select {
    width: 325px;
  }
}

@media print {
  .print-col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
  }

  .print-move-right {
    right: -135px;
  }
  .color_0 {
    fill: Red;
    print-color-adjust: exact !important;
  }
  .print-position-absolute {
    position: absolute;
  }
  .print-allow-page-break {
    margin-top: 300px;
  }

  .btn.btn-link.text-print-black {
    color: #000;
  }

  .breakPageAfter {
    page-break-after: always;
  }
  .mark,
  mark {
    font-weight: bold;
  }

  .text-print-scale {
    font-size: 175% !important;
  }

  .btn.btn-link.text-print-black.text-print-scale {
    font-size: 110% !important;
  }
}
