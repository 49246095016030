.comparison-report-location-selector {
    border: 1px solid lightgrey;
    height: 350px;
    min-height: 350px;
    overflow-y: scroll;
    padding: 10px;
}

.modal-content {
}

.modal-header {
    background-color: #00695c;
    padding: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    color: white;
    padding-left: 20px;
}

.modal-content {
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
    -webkit-border: 0px !important;
    -moz-border: 0px !important;
    border: 0px !important;
}



.modal-backdrop {
    background-color: transparent;
}
.modal-backdrop-orange {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: grey;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: 1040;
}
.modal-backdrop-transparent {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: -1000;
}
.modal-transition {
    transition: all 0.4s ease;
}